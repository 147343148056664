























import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/main/types';
import { NewsItem as Item } from '@/interfaces/NewsItem';
import { stampToReadable } from '@/utilities/filters';

@Component({
  filters: { stampToReadable },
})
export default class NewsItem extends Vue {

  @Getter('news', { namespace: 'main' }) private getNews!: Item[];
  @Action('loadNews', { namespace: 'main' }) private loadNews!: types.LoadNewsAction;

  private loaded = false;

  private created() {
    if (this.getNews.length === 0) {
      this.loadNews().then(() => {
        this.loaded = true;
      });
    } else {
      this.loaded = true;
    }
  }

  private get item() {
    if (!this.loaded) {
      return {};
    }
    const id =  parseInt(this.$route.params.id, 10);
    const newsItem = this.getNews.find((item) => item.id === id);
    if (newsItem === undefined) {
      this.$router.push({ name: 'not-found' });
    } else {
      return newsItem;
    }
  }

}
